var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[(_vm.album != null)?_c('div',[_c('div',{staticClass:"workfolder-title"},[_c('small',[_vm._v("Arbeitsmappe")]),_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.album.title)+" ")])]),_c('div',[_c('ButtonMenu')],1),_c('div',{on:{"dragstart":_vm.onDragStart,"dragend":_vm.onDragEnd}},[_c('div',{staticClass:"draggable-list"},[_c('TransitionGroup',{staticClass:"container",attrs:{"tag":"ul","name":"fade"}},[_vm._l((_vm.album.folders),function(element,idx){return _c('div',{key:element.id,class:{'drop-zone-wrapper': true, 'hidden': element.hidden},attrs:{"id":element.id}},[_c('div',[(element.id !== 'placeholderFolder' &&
                  (_vm.album.folders[idx - 1] || {}).id !== 'placeholderFolder')?_c('DropZoneLine',{attrs:{"drag-start-element":_vm.currentFolderElement,"idx":idx,"is-visible":_vm.currentFolderElement &&
                  _vm.draggedFolderIndex != idx &&
                  _vm.draggedFolderIndex != idx-1},on:{"add-placeholder-folder":_vm.addPlaceholderFolder}}):_c('div')],1),(element.id !== 'placeholderFolder')?_c('BaseSlideBox',{attrs:{"id":element.id,"slides":element.items,"current-folder-element":_vm.currentFolderElement,"current-folder-element-is-full":_vm.currentFolderElementIsFull},on:{"create-folder":_vm.onCreateFolder,"add-to-folder":_vm.onAddToFolder,"remove-from-folder":_vm.onRemoveFromFolder,"split-images":_vm.splitImages,"position-left":_vm.positionLeft,"position-right":_vm.positionRight,"reorder-folder":_vm.reorderFolder,"switch-images":_vm.switchImages,"on-drag-end":_vm.onDragEnd}}):_c('PlaceholderFolder',{attrs:{"idx":idx},on:{"remove-folder":_vm.removeFolder}}),_c('div',[(element.id !== 'placeholderFolder' &&
                  (_vm.album.folders[idx + 1] || {}).id !== 'placeholderFolder')?_c('DropZoneLine',{attrs:{"drag-start-element":_vm.currentFolderElement,"idx":idx + 1,"is-visible":_vm.currentFolderElement &&
                  _vm.draggedFolderIndex != idx &&
                  _vm.draggedFolderIndex != idx+1},on:{"add-placeholder-folder":_vm.addPlaceholderFolder}}):_c('div')],1)],1)}),_c('base-box-button',{key:'plus-button-box',staticClass:"box",attrs:{"icon":"plus","text":"Neues Kunstwerk zur Arbeitsmappe hinzufügen","show-title":false,"box-size":{ width: 'unset' }}}),_c('base-box-button',{key:'download-button-box',staticClass:"box",attrs:{"icon":"download","text":"Arbeitsmappe herunterladen","show-title":false,"box-size":{ width: 'unset' }}}),_c('base-box-button',{key:'people-button-box',staticClass:"box",attrs:{"icon":"people","box-style":"large","text":"Personen zum Bearbeiten einladen","show-title":false,"box-size":{ width: 'unset' }}})],2)],1)])]):_c('div',[_c('p',[_vm._v("Nothing found!")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }